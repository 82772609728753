import React from 'react';
import { Link } from 'gatsby';
//import logo from '../../images/logo-top.svg';
import Classes from  '../../assets/sass/main.scss';
import NavBarMenu from './navbar';
const Header = () => (
  <>
      {/* <nav class="left">
      
      */}
    <NavBarMenu />
    </>
);

export default Header;
